
import { reactive, toRefs, onBeforeMount, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { api } from '@/api/useAxios'
import { Dialog, Toast } from 'vant';



export default {
  name: "",
  setup() {
    const router = useRouter();
    const companyInfo = ref<any>()
    const companyId = router.currentRoute.value.query?.companyId;
    if(companyId){
        api.get('/companyinfo/info/'+companyId).then((r) => {
            if(r){
                companyInfo.value = r
            }
        })
    }
   
    const join = () => {
        Dialog.confirm({
            title: '确认提交申请',
            message: '请确认是否申请加入该企业',
            confirmButtonText: '确定'
        })
        .then(() => {
            api.post('/companyemployeeinfo/joinapply', {
              companyId: companyId
            }).then((r) => {
                if(r){
                    Toast.success("申请成功，请等待审核！")
                }
            })
        })
        .catch(() => {
            // on cancel
        })
    }

    return {
      companyId,
      companyInfo,
      join,
      goBack: () => {
        router.push("/");
      },
    };
  },
};
